import styled from "styled-components";

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const InfoContentWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 12px 24px;
  max-width: 800px;
  color: var(--custom-black);
`;

export const InfoH1 = styled.h1`
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const InfoP = styled.p`
  font-size: 16px;
  line-height: 24px;
`;
