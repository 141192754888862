import { InfoP } from "./InfoElements";

export const aboutData = {
  header: "Ihre Versicherungsagentur 👨🏼‍💼",
  body: (
    <>
      <InfoP>
        Diese Website ist so simpel und einfach aufgebaut, wie der Abschluss
        Ihrer Vesicherung. Simpel, einfach und transparent, so sollte der
        Vertrieb von Versicherungen in meinen Augen sein.
        <br />
        <br />
        Mein Name ist Daniel Schlosser und ich freue mich von Ihnen zu hören, um
        Sie bei Ihrer nächsten Versicherung zu beraten, egal ob Privat- oder
        Firmenkunde. Zu meinen Leistungen zählen unter anderem:
      </InfoP>
    </>
  ),
};

export const privacyData = {
  header: "Datenschutz 🔒",
  body: (
    <>
      <InfoP>
        Ihre Privatsphäre ist mir ein Anliegen. Ich versichere Ihnen aus diesem
        Grund, dass diese Website keine Cookies verwendet, keine
        personenbezogene Daten erhebt oder speichert und somit auch keine Daten
        an Drittanbieter weitergibt.
      </InfoP>
    </>
  ),
};

export const imprintData = {
  header: "Kontakt 📲",
  body: (
    <>
      <InfoP>
        Daniel Schlosser
        <br />
        Versicherungsagentur
        <br />
        Hietzinger Hauptstraße 95
        <br />
        1130 Wien
        <br />
        AUSTRIA
        <br />
        <br />
        E-mail: office@va-schlosser.at
        <br />
        Telefon: +43 664 101 73 77
      </InfoP>
    </>
  ),
};
