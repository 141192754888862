import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

#root {
  --custom-black: #121212;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body.preload *{ animation-duration: 0s !important; -webkit-animation-duration: 0s !important; transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;} 

html, body {
    overflow-x: hidden;
    background: #f9f9f9;
    word-break: break-word;
    margin: 0;
    font-family: "Inter", sans-serif;
    padding: 0;
  }
`;
