import React from "react";
import { InfoP } from "../InfoSection/InfoElements";
import {
  ServicesContainer,
  ServicesContentWrapper,
  ServicesListItem,
  ServicesListWrapper,
} from "./ServicesElements";

function ServicesSection() {
  return (
    <>
      <ServicesContainer>
        <ServicesContentWrapper>
          <ServicesListWrapper>
            <ServicesListItem>Haushaltsversicherung</ServicesListItem>
            <ServicesListItem>Kfz Versicherung</ServicesListItem>
            <ServicesListItem>Lebensversicherung</ServicesListItem>
            <ServicesListItem>Unfallversicherung</ServicesListItem>
            <ServicesListItem>Rechtsschutzversicherung</ServicesListItem>
            <ServicesListItem>Hausversicherung</ServicesListItem>
            <ServicesListItem>Krankenversicherung</ServicesListItem>
            <ServicesListItem>Haftpflichtversicherung</ServicesListItem>
            <ServicesListItem>Kaskoversicherung</ServicesListItem>
            <ServicesListItem>Betriebshaftpflichtversicherung</ServicesListItem>
            <ServicesListItem>Sturmschadenversicherung</ServicesListItem>
          </ServicesListWrapper>
          <InfoP>
            Ihr Anliegen ist hier nicht aufgelistet? Kontaktieren Sie mich
            trotzdem, ich bin zuversichtlich, dass ich Ihnen behilflich sein
            kann.
          </InfoP>
        </ServicesContentWrapper>
      </ServicesContainer>
    </>
  );
}

export default ServicesSection;
