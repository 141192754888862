import styled from "styled-components";

export const ServicesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ServicesContentWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 12px 24px;
  max-width: 800px;
  color: var(--custom-black);
`;

export const ServicesListWrapper = styled.ul`
  list-style: square;
`;

export const ServicesListItem = styled.li``;
