import InfoSection from "./components/InfoSection";
import HeaderSection from "./components/HeaderSection";
import ServicesSection from "./components/ServicesSection";
import {
  aboutData,
  imprintData,
  privacyData,
} from "./components/InfoSection/Data";
import { GlobalStyle } from "./styles/GlobalStyle";

function App() {
  return (
    <>
      <GlobalStyle />
      <HeaderSection />
      <InfoSection {...aboutData} />
      <ServicesSection />
      <InfoSection {...imprintData} />
      <InfoSection {...privacyData} />
    </>
  );
}

export default App;
