import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  padding: 24px;
  color: var(--custom-black);
`;

export const Header = styled.h1`
  font-weight: bold;
  font-size: 44px;

  @media screen and (max-width: 480px) {
    font-size: 36px;
  }
`;

export const ContactLink = styled.a`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: var(--custom-black);
  padding-top: 5px;

  &:hover {
    color: #454545;
  }
`;

export const ImgWrapper = styled.div`
  height: 40px;
  width: 40px;
`;

export const Img = styled.img`
  height: 40px;
  width: 40px;
`;
