import React from "react";
import {
  ContactLink,
  Header,
  HeaderContainer,
  HeaderContentWrapper,
  Img,
  ImgWrapper,
} from "./HeaderElements";
import Logo from "../../assets/schlosser_logo.svg";

function HeaderSection() {
  return (
    <>
      <HeaderContainer>
        <HeaderContentWrapper>
          <ImgWrapper>
            <Img src={Logo} />
          </ImgWrapper>
          <Header>Daniel Schlosser</Header>
          <ContactLink href="tel:+436641017377" target="_blank">
            +43 664 101 73 77
          </ContactLink>
          <ContactLink href="mailto:office@va-schlosser.at" target="_blank">
            office@va-schlosser.at
          </ContactLink>
        </HeaderContentWrapper>
      </HeaderContainer>
    </>
  );
}

export default HeaderSection;
