import React from "react";
import { InfoContainer, InfoContentWrapper, InfoH1 } from "./InfoElements";

function InfoSection({ header, body }) {
  return (
    <>
      <InfoContainer>
        <InfoContentWrapper>
          <InfoH1>{header}</InfoH1>
          {body}
        </InfoContentWrapper>
      </InfoContainer>
    </>
  );
}

export default InfoSection;
